// 设备详情——设备列表数据
const equList = [
  {
    equId: "MY20230301010",
    workShop: "模压车间",
    entry: "2023年6月30日",
  },
  {
    equId: "MY20230301011",
    workShop: "模压车间",
    entry: "2023年6月30日",
  },
  {
    equId: "MY20230301012",
    workShop: "模压车间",
    entry: "2023年6月30日",
  },
  {
    equId: "MY20230301013",
    workShop: "模压车间",
    entry: "2023年6月30日",
  },
];
const equColum = [
  { prop: "equId", label: "设备编号" },
  { prop: "workShop", label: "所在车间" },
  { prop: "entry", label: "入场时间" },
];
// 设备详情——零件清单数据
const partList = [
  {
    partId: "LJ20230301010",
    partName: "零件A",
    date: "2023年6月30日",
  },
  {
    partId: "LJ20230301012",
    partName: "零件B",
    date: "2023年6月30日",
  },
  {
    partId: "LJ20230301013",
    partName: "零件C",
    date: "2023年6月30日",
  },
  {
    partId: "LJ20230301014",
    partName: "零件D",
    date: "2023年6月30日",
  },
];
const partColum = [
  { prop: "partId", label: "零件编号" },
  { prop: "partName", label: "零件名称" },
  { prop: "date", label: "创建日期" },
];
export { equList, equColum, partList, partColum };
